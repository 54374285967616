import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  ExpandableSection,
  FormField,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";

const AbvCalculator: React.FC = () => {
  const [originalGravity, setOriginalGravity] = useState<string>("1.100");
  const [finalGravity, setFinalGravity] = useState<string>("1.000");
  const [abv, setAbv] = useState<string>("0.00");

  const [expanded, setExpanded] = useLocalStorage("expandedAbv", true);

  const calculateAbv = () => {
    const calculatedAbv =
      (parseFloat(originalGravity) - parseFloat(finalGravity)) * 131.25;
    setAbv(calculatedAbv.toFixed(2));
  };

  useEffect(() => {
    if (originalGravity && finalGravity) calculateAbv();
  }, [originalGravity, finalGravity]);

  return (
    <ExpandableSection
      expanded={expanded}
      onChange={({ detail }) => setExpanded(detail.expanded)}
      headerText="ABV Calculator"
    >
      <SpaceBetween size="m">
        <FormField label="Original gravity">
          <Input
            value={originalGravity}
            onChange={({ detail }) => setOriginalGravity(detail.value)}
          />
        </FormField>
        <FormField label="Final gravity">
          <Input
            value={finalGravity}
            onChange={({ detail }) => setFinalGravity(detail.value)}
          />
        </FormField>
        <Box variant="h3" fontWeight="heavy">
          <span>ABV: {abv}%</span>
        </Box>
      </SpaceBetween>
    </ExpandableSection>
  );
};

export default AbvCalculator;
