import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  getDocs,
  onSnapshot,
  Query,
  query,
  QuerySnapshot,
  updateDoc,
  where,
  WhereFilterOp,
} from "@firebase/firestore";

import { db } from "../config/firebase";

export type Filter<T> = {
  field: keyof T;
  operator: WhereFilterOp;
  value: any;
};

export const createItem = async <T extends { id?: string }>(
  collectionName: string,
  item: Omit<T, "id">
): Promise<T> => {
  try {
    const docRef = await addDoc(collection(db, collectionName), {
      ...item,
    });

    return { ...item, id: docRef.id } as T;
  } catch (error) {
    console.error("Error creating item:", error);
    throw new Error("Failed to create item");
  }
};

export const updateItem = async <T extends { id: string }>(
  collectionName: string,
  itemId: string,
  item: Partial<Omit<T, "id">>
): Promise<T> => {
  try {
    const itemDoc = doc(db, collectionName, itemId);
    await updateDoc(itemDoc, { ...item });
    return { ...(item as T), id: itemId };
  } catch (error) {
    console.error("Error updating item:", error);
    throw new Error("Failed to update item");
  }
};

export const deleteItem = async (
  collectionName: string,
  itemId: string
): Promise<void> => {
  try {
    const itemDoc = doc(collection(db, collectionName), itemId);
    await deleteDoc(itemDoc);
    console.log(
      `Item with ID ${itemId} successfully deleted from ${collectionName}`
    );
  } catch (error) {
    console.error("Error deleting item:", error);
    throw new Error("Failed to delete item");
  }
};

export const fetchItems = async <T extends { id?: string }>(
  collectionName: string
): Promise<T[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    const items: T[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as T)
    );

    return items;
  } catch (error) {
    console.error("Error fetching batches:", error);
    throw new Error("Failed to fetch batches");
  }
};

export const fetchItemsWithFilter = async <T extends { id?: string }>(
  collectionName: string,
  filters: Filter<T>[] = [] // Accept an array of filters
): Promise<T[]> => {
  try {
    // Start with the collection reference
    const collectionRef: CollectionReference = collection(db, collectionName);

    // Dynamically apply all filters to the query
    let q: Query = query(collectionRef); // Start as a query
    filters.forEach((filter) => {
      q = query(
        q,
        where(filter.field as string, filter.operator, filter.value)
      );
    });

    // Fetch documents
    const querySnapshot = await getDocs(q);

    // Map the documents to an array of items
    const items: T[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as T)
    );

    return items;
  } catch (error) {
    console.error("Error fetching items with filters:", error);
    throw new Error("Failed to fetch items with filters");
  }
};

export const subscribeToChanges = <T extends { id?: string }>(
  collectionName: string,
  onChange: (items: T[]) => void,
  onError: (error: Error) => void,
  itemId?: string
): (() => void) => {
  const collectionRef = collection(db, collectionName);
  const unsubscribe = itemId
    ? onSnapshot(
        doc(collectionRef, itemId),
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const item = { ...docSnapshot.data(), id: docSnapshot.id } as T;
            onChange([item]);
          } else {
            onError(new Error("Document does not exist"));
          }
        },
        (error) => {
          console.error("Error subscribing to document changes:", error);
          onError(new Error("Failed to subscribe to document changes"));
        }
      )
    : onSnapshot(
        collectionRef,
        (querySnapshot: QuerySnapshot<DocumentData>) => {
          const items: T[] = querySnapshot.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              } as T)
          );
          onChange(items);
        },
        (error) => {
          console.error("Error subscribing to collection changes:", error);
          onError(new Error("Failed to subscribe to collection changes"));
        }
      );

  return unsubscribe;
};

export const subscribeToFilteredChanges = <T extends { id?: string }>(
  collectionName: string,
  filters: Filter<T>[],
  onChange: (items: T[]) => void,
  onError: (error: Error) => void
): (() => void) => {
  try {
    // Start with the collection reference
    const collectionRef: CollectionReference = collection(db, collectionName);

    // Dynamically apply all filters to the query
    let q: Query = query(collectionRef); // Start as a query
    filters.forEach((filter) => {
      q = query(
        q,
        where(filter.field as string, filter.operator, filter.value)
      );
    });

    // Subscribe to changes
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const items: T[] = querySnapshot.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              id: doc.id,
            } as T)
        );
        onChange(items);
      },
      (error) => {
        console.error("Error subscribing to filtered changes:", error);
        onError(new Error("Failed to subscribe to filtered changes"));
      }
    );

    return unsubscribe; // Return the unsubscribe function
  } catch (error) {
    console.error("Error setting up filtered subscription:", error);
    onError(new Error("Failed to set up filtered subscription"));
    return () => {}; // Return a no-op unsubscribe if an error occurs
  }
};

export const getCount = async (collectionName: string): Promise<number> => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  return querySnapshot.size;
};
