import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Button,
  ContentLayout,
  Header,
  Table,
} from "@cloudscape-design/components";

import { deleteItem as deleteInv } from "../api/generic";
import ErrorBar from "../components/ErrorBar";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import InventoryItem from "../models/InventoryItem";
import { prefixCollection } from "../utils/prefix";
import { sortName } from "../utils/sort";

const Inventory: FC = () => {
  const { tenant } = useAuthContext();
  const navigate = useNavigate();
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [error, setError] = useState<Error>();

  useSubscribe<InventoryItem>(
    prefixCollection("inventory", tenant),
    setInventory,
    setError,
    sortName
  );

  const deleteItem = async (item: InventoryItem) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete ${item.name}?`
    );
    if (!confirmed) return;
    await deleteInv("inventory", item.id);
  };

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description={"Hint: You can add items and set a quantity later"}
          actions={
            <Button
              iconName="add-plus"
              variant="primary"
              onClick={() => navigate("/Inventory/new")}
            >
              Add ingredient
            </Button>
          }
        >
          Inventory
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <ErrorBar error={error} setError={setError} />
        <Table
          variant="embedded"
          items={inventory}
          columnDefinitions={[
            {
              header: "Name",
              cell: (item) => (
                <Link to={`/Inventory/${item.id}`}>{item.name}</Link>
              ),
              isRowHeader: true,
            },
            {
              header: "Quantity",
              cell: (item) => (
                <>
                  {item.quantity} {item.unit}
                </>
              ),
            },
            {
              header: "Price per unit",
              cell: (item) => (
                <>
                  €{item.price}/{item.unit}
                </>
              ),
            },
            {
              header: "Value",
              cell: (item) => (
                <>€{((item.price ?? 0) * item.quantity).toFixed(2)}</>
              ),
            },
            {
              header: "Actions",
              cell: (item) => (
                <>
                  <Button
                    iconName="remove"
                    variant="inline-link"
                    onClick={() => deleteItem(item)}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </ContentLayout>
  );
};

export default Inventory;
