import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "react-use";

import {
  Badge,
  Box,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  ContentLayout,
  DatePicker,
  ExpandableSection,
  FormField,
  Header,
  Icon,
  Input,
  LineChart,
  Modal,
  Select,
  Slider,
  SpaceBetween,
} from "@cloudscape-design/components";
import { doc, Timestamp, updateDoc } from "@firebase/firestore";

import { createBatch, fetchBatch, updateBatch } from "../api/batches";
import { deleteItem } from "../api/generic";
import { fetchRecipe } from "../api/recipes";
import ErrorBar from "../components/ErrorBar";
import GravitySample from "../components/GravitySampleModal";
import HydroPi from "../components/modules/HydroPi";
import HydroPiConfigModal from "../components/modules/HydroPiConfig";
import UpdateQuantityModal from "../components/UpdateQuantityModel";
import { db } from "../config/firebase";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import { Batch } from "../models/Batch";
import { Recipe } from "../models/Recipe";
import { GravitySample as GS, TasteSample } from "../models/Sampling";
import { Settings } from "../models/Settings";
import { costPerBottle, costPerUnit, costTotal } from "../utils/cost";
import { prefixCollection } from "../utils/prefix";
import { sortGravitySamples, sortName } from "../utils/sort";
import { addDaysBySteps, toDate } from "../utils/timestamp";
import TasteSampleModal from "../components/TasteSampleModal";
import { v4 } from "uuid";
import TasteVisualization from "../components/TasteRadarChart";

export const renderStars = (score: number) => {
  const stars = [];
  const starCount = Math.round((score / 100) * 2) / 2; // Round to nearest 0.5
  const fullStars = Math.floor(starCount); // Number of full stars
  const halfStar = starCount % 1 !== 0; // Check if a half star is needed
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Remaining empty stars

  // Add full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(<Icon key={`full-${i}`} name="star-filled" />);
  }

  // Add half star if applicable
  if (halfStar) {
    stars.push(<Icon key="half" name="star-half" />);
  }

  // Add empty stars
  for (let i = 0; i < emptyStars; i++) {
    stars.push(<Icon key={`empty-${i}`} name="star" />);
  }

  return stars;
};

export const initalBatch: Batch = {
  id: "",
  name: "",
  recipeId: "",
  startDate: new Date(),
  status: "planned",
  quantity: 0,
  volume: 0,
  yield: 0,
  expectedYield: 0,
  currentStepIndex: 0,
  stepStatus: "not-started",
};

const BatchPage = () => {
  const { tenant } = useAuthContext();
  let { id, modal } = useParams();
  const navigate = useNavigate();
  const [ingredientsExpanded, setIngredientsExpanded] =
    useLocalStorage<boolean>("ingredientsExpanded", false);
  const [stepsExpanded, setStepsExpanded] = useLocalStorage<boolean>(
    "stepsExpanded",
    false
  );
  const [currentStepExpanded, setCurrentStepExpanded] =
    useLocalStorage<boolean>("currentStepExpanded", false);
  const [gravityExpanded, setGravityExpanded] = useLocalStorage<boolean>(
    "gravityExpanded",
    false
  );
  const [costExpanded, setCostExpanded] = useLocalStorage<boolean>(
    "costExpanded",
    false
  );
  const [tasteExpanded, setTasteExpanded] = useLocalStorage<boolean>(
    "tasteExpanded",
    false
  );

  const [batch, setBatch] = useState<Batch | null>(null);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [gravitySamples, setGravitySamples] = useState<GS[]>([]);
  const [notes, setNotes] = useState("");
  const [score, setScore] = useState(-1);

  const [editMode, setEditMode] = useState<boolean>(id === "new");

  const [quantity, setQuantity] = useState("");
  const [volume, setVolume] = useState("");
  const [yieldAmount, setYieldAmount] = useState("");
  const [expectedYield, setExpectedYield] = useState("");

  const [showHydroPiModal, setShowHydroPiModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [editStep, setEditStep] = useState(-1);

  const [finishModal, setFinishModal] = useState(false);
  const [error, setError] = useState<Error>();
  const [settings, setSettings] = useState<Settings>();

  const [showTasteModal, setShowTasteModal] = useState(false);

  useSubscribe<Recipe>(
    prefixCollection("recipes", tenant),
    setRecipes,
    setError,
    sortName
  );
  useSubscribe<GS>(
    prefixCollection("gravity", tenant),
    setGravitySamples,
    setError,
    sortGravitySamples,
    (item) => item.batchId === id
  );
  useSubscribe<Settings>(
    prefixCollection("settings", tenant),
    (items) => setSettings(items[0]),
    setError
  );

  useEffect(() => {
    if (editMode) return;
    if (id === "new") {
      setBatch(initalBatch);
      return;
    }
    if (!id) return;
    fetchBatch(id, tenant).then(setBatch);
  }, [editMode, id, tenant]);

  useEffect(() => {
    if (!id || id === "new") return;
    fetchBatch(id, tenant).then((b) => {
      setQuantity(b?.quantity?.toString() ?? "");
      setVolume(b?.volume?.toString() ?? "");
      setYieldAmount(b?.yield?.toString() ?? "");
      setExpectedYield(b?.expectedYield?.toString() ?? "");
      setNotes(b?.notes ?? "");
      setScore(b?.score ?? -1);

      setStartDate(
        (b?.startDate as Timestamp).toDate()?.toISOString().split("T")[0] ?? ""
      );
      setBatch(b);
    });
  }, [id, navigate, tenant]);

  useEffect(() => {
    if (!batch) return;
    if (batch.recipeId !== "" && !batch.recipe) {
      fetchRecipe(batch.recipeId, tenant).then((r) => {
        if (!r) return;
        setBatch((prevBatch) => {
          if (!prevBatch) return { ...initalBatch, recipe: r };
          return { ...prevBatch, recipe: r };
        });
      });
    }
  }, [batch, tenant]);

  const saveBatch = async () => {
    if (!batch) return;

    if (batch.id === "") {
      const docRef = await createBatch(
        {
          ...batch,
          quantity: parseInt(quantity),
          volume: parseFloat(volume),
          yield: parseFloat(yieldAmount),
          expectedYield: parseFloat(expectedYield),
        },
        tenant
      );
      navigate(`/batches/${docRef.id}`);
    } else {
      const newBatchData = {
        ...batch,
        quantity: parseInt(quantity),
        volume: parseFloat(volume),
        yield: parseFloat(yieldAmount),
        expectedYield: parseFloat(expectedYield),
        notes,
        score,
      };
      await updateBatch(batch.id, newBatchData, tenant);
      setBatch(newBatchData);
    }
  };

  const handleAddTasteSample = async (
    sample: Omit<TasteSample, "id" | "batchId">
  ) => {
    const newSample: TasteSample = {
      ...sample,
      id: v4(), // Unique ID for the sample
      batchId: batch?.id || "", // Link to the current batch
    };

    const updatedSamples = [...(batch?.tasteSamples ?? []), newSample];

    // Save updated samples to Firestore (optional)
    if (batch) {
      await updateBatch(
        batch.id,
        { ...batch, tasteSamples: updatedSamples },
        tenant
      );
      if (id && id !== "new") {
        fetchBatch(id, tenant).then(setBatch);
      }
    }
  };

  const cancelEdit = () => {
    setEditMode(false);
    if (id && id !== "new") {
      fetchBatch(id, tenant).then(setBatch);
    } else {
      navigate(`/batches`);
    }
  };

  const archiveBatch = async () => {
    if (!batch) return;
    const confirm = window.confirm(
      "Are you sure you want to archive this batch?"
    );
    if (!confirm) return;
    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, { status: "archived" });
    navigate(`/batches`);
  };

  const reActivateBatch = async () => {
    if (!batch) return;
    const confirm = window.confirm(
      "Are you sure you want to reactivate this batch?"
    );
    if (!confirm) return;
    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, { status: "planned" });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };

  const stepStart = async () => {
    if (!batch) return;
    const newRecipe = batch.recipe;
    if (!newRecipe) return;

    const newStep = newRecipe.steps[batch.currentStepIndex];
    if (!newStep) return;

    newStep.start = new Date();

    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, {
      stepStatus: "in-progress",
      status: "in-progress",
      recipe: newRecipe,
    });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };
  const stepComplete = async () => {
    if (!batch) return;

    const newRecipe = batch.recipe;
    if (!newRecipe) return;

    const newStep = newRecipe.steps[batch.currentStepIndex];
    if (!newStep) return;

    newStep.end = new Date();

    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, { stepStatus: "completed", recipe: newRecipe });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };
  const stepNext = async () => {
    if (!batch) return;
    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, {
      currentStepIndex: batch.currentStepIndex + 1,
      stepStatus: "not-started",
    });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };

  const stepPrevious = async () => {
    if (!batch) return;
    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, {
      currentStepIndex: batch.currentStepIndex - 1,
      stepStatus: "not-started",
    });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };

  const updateBatchStatus = async (
    status: "planned" | "in-progress" | "completed" | "archived"
  ) => {
    if (!batch) return;
    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, { status });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };

  const handleDelete = async () => {
    if (window.confirm("Delete the batch?!") && id) {
      await deleteItem(prefixCollection("batches", tenant), id);
      navigate("/batches");
    }
  };

  return (
    <>
      <ContentLayout
        disableOverlap
        header={
          <Header
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button
                  onClick={() => navigate("/batches")}
                  iconName="angle-left"
                >
                  Back
                </Button>
                <Button
                  variant={editMode ? "primary" : "normal"}
                  onClick={() => {
                    if (editMode) {
                      saveBatch();
                    }
                    setEditMode((x) => !x);
                  }}
                  iconName={"edit"}
                >
                  {editMode ? "Save" : "Edit batch"}
                </Button>
                <Button
                  onClick={() => navigate("/batches/" + id + "/recipe/recipe")}
                  iconName="edit"
                >
                  Recipe
                </Button>
                <Button onClick={handleDelete} iconName="delete-marker">
                  Delete
                </Button>
                {editMode && <Button onClick={cancelEdit}>Cancel</Button>}
                {id &&
                  id !== "new" &&
                  (batch?.status !== "archived" ? (
                    <Button onClick={archiveBatch} iconName="download">
                      Archive
                    </Button>
                  ) : (
                    <Button onClick={reActivateBatch} iconName="upload">
                      Reactivate
                    </Button>
                  ))}
                {settings &&
                  settings.integrations
                    .map((x) => x.integrationName)
                    .includes("squarespace") && (
                    <Button
                      onClick={() => setFinishModal(true)}
                      iconName="status-positive"
                    >
                      Add to stock
                    </Button>
                  )}
              </SpaceBetween>
            }
            variant="h1"
            description={
              id === "new"
                ? "Create new batch"
                : (batch?.recipe?.name ?? "") +
                  " - " +
                  (batch?.recipe?.style ?? "")
            }
          >
            {id === "new" ? "Create new batch" : batch?.name ?? "N/A"}
          </Header>
        }
      >
        <div style={{ marginTop: 10 }}>
          <ErrorBar error={error} setError={setError} />
          <Container
            header={
              <Header
                actions={
                  id &&
                  id !== "new" && (
                    <ButtonDropdown
                      onItemClick={({ detail }) => {
                        if (detail.id === "gravity")
                          navigate(`/batches/${id}/modal/gravity`);
                        else if (detail.id === "hydropi")
                          setShowHydroPiModal(true);
                        else if (detail.id === "taste") setShowTasteModal(true);
                        else
                          updateBatchStatus(
                            detail.id as
                              | "planned"
                              | "in-progress"
                              | "completed"
                              | "archived"
                          );
                      }}
                      items={[
                        {
                          text: "Status",
                          items: [
                            {
                              disabled: batch?.status === "planned",
                              text: "Planned",
                              id: "planned",
                            },
                            {
                              disabled: batch?.status === "in-progress",
                              text: "In progress",
                              id: "in-progress",
                            },
                            {
                              disabled: batch?.status === "completed",
                              text: "Completed",
                              id: "completed",
                            },
                            {
                              disabled: batch?.status === "archived",
                              text: "Archived",
                              id: "archived",
                            },
                          ],
                        },
                        {
                          text: "Sample",
                          items: [
                            { text: "Gravity", id: "gravity" },
                            { text: "Taste", id: "taste" },
                            { text: "HydroPi Config", id: "hydropi" },
                          ],
                        },
                      ]}
                      ariaLabel="Sample batch"
                      variant="inline-icon"
                    />
                  )
                }
              >
                General information
              </Header>
            }
          >
            <ColumnLayout columns={2} variant="text-grid">
              <FormField label="Batch name">
                {editMode ? (
                  <Input
                    value={batch?.name ?? ""}
                    onChange={({ detail }) =>
                      setBatch((prevBatch) => {
                        if (!prevBatch)
                          return {
                            ...initalBatch,
                            name: detail.value,
                          };
                        return { ...prevBatch, name: detail.value };
                      })
                    }
                  />
                ) : (
                  batch?.name ?? "N/A"
                )}
              </FormField>
              <FormField label="Recipe">
                {editMode ? (
                  <Select
                    disabled={id !== "new"}
                    selectedOption={{
                      label:
                        (batch?.recipe?.name ?? "") +
                        " - " +
                        (batch?.recipe?.style ?? ""),
                      value: batch?.recipe?.id ?? "",
                    }}
                    onChange={({ detail }) =>
                      setBatch((prevBatch) => {
                        if (!prevBatch)
                          return {
                            ...initalBatch,
                            recipeId: detail.selectedOption.value ?? "",
                            recipe: recipes.find(
                              (r) => r.id === detail.selectedOption.value
                            ),
                          };
                        return {
                          ...prevBatch,
                          recipeId: detail.selectedOption.value ?? "",
                          recipe: recipes.find(
                            (r) => r.id === detail.selectedOption.value
                          ),
                        };
                      })
                    }
                    options={
                      recipes.map((recipe) => ({
                        label: recipe.name + " - " + recipe.style,
                        value: recipe.id,
                      })) ?? []
                    }
                  />
                ) : (
                  (batch?.recipe?.name ?? "") +
                  " - " +
                  (batch?.recipe?.style ?? "")
                )}
              </FormField>
              <FormField label="Status">
                <SpaceBetween size="m" direction="horizontal">
                  {editMode ? (
                    <Select
                      selectedOption={{
                        label: (
                          (batch?.status ?? "planned").charAt(0).toUpperCase() +
                          (batch?.status ?? "planned").slice(1)
                        ).replace("-", " "),
                        value: batch?.status ?? "planned",
                      }}
                      onChange={({ detail }) =>
                        setBatch((prevBatch) => {
                          if (!prevBatch)
                            return {
                              ...initalBatch,
                              status:
                                (detail.selectedOption?.value as
                                  | "planned"
                                  | "in-progress"
                                  | "completed"
                                  | "archived") ?? "planned",
                            };
                          return {
                            ...prevBatch,
                            status:
                              (detail.selectedOption.value as
                                | "planned"
                                | "in-progress"
                                | "completed"
                                | "archived") ?? "planned",
                          };
                        })
                      }
                      options={[
                        { label: "Planned", value: "planned" },
                        { label: "In progress", value: "in-progress" },
                        { label: "Completed", value: "completed" },
                        { label: "Archived", value: "archived" },
                      ]}
                    />
                  ) : (
                    <Badge
                      color={
                        batch?.status === "in-progress"
                          ? "blue"
                          : batch?.status === "completed"
                          ? "green"
                          : "grey"
                      }
                    >
                      {(
                        (batch?.status ?? "planned").charAt(0).toUpperCase() +
                        (batch?.status ?? "planned").slice(1)
                      ).replace("-", " ")}
                    </Badge>
                  )}
                </SpaceBetween>
              </FormField>
              <FormField
                label="Quantity (L)"
                info="Related to the recipe ingredients size. (Q/R)*I"
              >
                {editMode ? (
                  <Input
                    value={quantity}
                    onChange={({ detail }) => setQuantity(detail.value)}
                  />
                ) : (
                  batch?.quantity ?? "N/A"
                )}
              </FormField>
              <FormField
                label="Volume (L)"
                info="What goes into your fermenter."
              >
                {editMode ? (
                  <Input
                    value={volume}
                    onChange={({ detail }) => setVolume(detail.value)}
                  />
                ) : (
                  batch?.volume ?? "N/A"
                )}
              </FormField>
              <FormField label="Yield (L)" info="The amount that is drinkable.">
                {editMode ? (
                  <Input
                    value={yieldAmount}
                    onChange={({ detail }) => setYieldAmount(detail.value)}
                  />
                ) : (
                  batch?.yield ?? "N/A"
                )}
              </FormField>

              <FormField
                label="Expected yield (L)"
                info="The amount that is expected to be drinkable."
              >
                {editMode ? (
                  <Input
                    value={expectedYield}
                    onChange={({ detail }) => setExpectedYield(detail.value)}
                  />
                ) : (
                  batch?.expectedYield ?? "N/A"
                )}
              </FormField>

              <FormField
                label="Start date"
                constraintText="Use YYYY/MM/DD format."
              >
                {editMode ? (
                  <DatePicker
                    value={startDate}
                    onChange={({ detail }) => {
                      setStartDate(detail.value);
                      setBatch((prevBatch) => {
                        if (!prevBatch)
                          return {
                            ...initalBatch,
                            startDate: new Date(detail.value),
                          };
                        return {
                          ...prevBatch,
                          startDate: new Date(detail.value),
                        };
                      });
                    }}
                  />
                ) : startDate ? (
                  new Date(startDate)?.toLocaleDateString()
                ) : (
                  "No date yet"
                )}
              </FormField>
              <FormField
                label="End date"
                constraintText="Use YYYY/MM/DD format."
              >
                {editMode ? (
                  <DatePicker
                    value={endDate}
                    onChange={({ detail }) => {
                      setEndDate(detail.value);
                      setBatch((prevBatch) => {
                        if (!prevBatch)
                          return {
                            ...initalBatch,
                            endDate: new Date(detail.value),
                          };
                        return {
                          ...prevBatch,
                          endDate: new Date(detail.value),
                        };
                      });
                    }}
                  />
                ) : endDate ? (
                  new Date(endDate)?.toLocaleDateString()
                ) : (
                  (batch?.startDate
                    ? addDaysBySteps(
                        toDate(batch.startDate),
                        batch?.recipe?.steps ?? []
                      )?.toLocaleDateString() ?? "No date yet"
                    : "No date yet") + " (expected)"
                )}
              </FormField>
              <FormField
                label="Notes"
                info="Add any additional notes for the batch."
              >
                {editMode ? (
                  <Input
                    value={notes}
                    onChange={({ detail }) => setNotes(detail.value)}
                  />
                ) : (
                  batch?.notes ?? "N/A"
                )}
              </FormField>
              <FormField label="Score" info="Rate the batch, 1 to 5.">
                {editMode ? (
                  <Slider
                    onChange={({ detail }) => setScore(detail.value)}
                    value={score}
                    valueFormatter={(value) => `${value / 100}`}
                    step={5}
                    max={500}
                    min={0}
                    referenceValues={[100, 200, 300, 400, 500]}
                  />
                ) : score < 0 ? (
                  ""
                ) : (
                  <>
                    {renderStars(score)} {(score ?? -1) / 100}
                  </>
                )}
              </FormField>
            </ColumnLayout>
          </Container>
        </div>
        <div style={{ marginTop: 10 }}>
          <ExpandableSection
            variant="container"
            headerText="Ingredients"
            expanded={ingredientsExpanded}
            onChange={({ detail }) => setIngredientsExpanded(detail.expanded)}
          >
            <ColumnLayout columns={2} variant="text-grid">
              {batch?.recipe?.ingredients.map((ingredient) => (
                <SpaceBetween
                  key={ingredient.item.id + ingredient.quantity}
                  size="m"
                  direction="horizontal"
                >
                  <Box variant="awsui-key-label">{ingredient.item.name}</Box>
                  <Box>
                    {(
                      ingredient.quantity *
                      ((batch?.quantity ?? 1) /
                        (batch?.recipe?.batchSize ?? 25))
                    ).toFixed(2)}{" "}
                    {ingredient.item.unit}{" "}
                    <small>
                      (€ {ingredient.item.price?.toFixed(2)}{" "}
                      <strong>
                        €{" "}
                        {(
                          (ingredient.item.price ?? 0) * ingredient.quantity
                        ).toFixed(2)}
                      </strong>
                      )
                    </small>
                  </Box>
                </SpaceBetween>
              ))}
            </ColumnLayout>
          </ExpandableSection>
        </div>

        <div style={{ marginTop: 10 }}>
          {(batch?.currentStepIndex ?? 0) <
            (batch?.recipe?.steps?.length ?? 99) && (
            <ExpandableSection
              headerActions={
                <SpaceBetween size="m" direction="horizontal">
                  {batch?.currentStepIndex !== 0 && (
                    <Button
                      variant="link"
                      iconName="angle-left"
                      onClick={stepPrevious}
                    />
                  )}
                  {batch?.stepStatus === "not-started" && (
                    <Button
                      variant="primary"
                      iconName="caret-right-filled"
                      onClick={stepStart}
                    />
                  )}
                  {batch?.stepStatus === "in-progress" && (
                    <Button
                      variant="primary"
                      iconName="status-pending"
                      onClick={stepComplete}
                    />
                  )}
                  {batch?.stepStatus === "completed" && (
                    <Button
                      variant="primary"
                      iconName="check"
                      onClick={stepNext}
                    />
                  )}
                </SpaceBetween>
              }
              variant="container"
              headerDescription={
                batch?.stepStatus === "completed"
                  ? "Done"
                  : batch?.stepStatus === "in-progress"
                  ? "In progress"
                  : "Not started"
              }
              headerText={
                "Current step - (" +
                ((batch?.currentStepIndex ?? 0) + 1) +
                ". " +
                (batch?.recipe?.steps[batch?.currentStepIndex ?? 0]?.name ??
                  "") +
                ")"
              }
              expanded={currentStepExpanded}
              onChange={({ detail }) => setCurrentStepExpanded(detail.expanded)}
            >
              {batch?.recipe?.steps[batch?.currentStepIndex ?? 0]
                ?.description ?? ""}
            </ExpandableSection>
          )}
        </div>

        <div style={{ marginTop: 10 }}>
          <ExpandableSection
            variant="container"
            headerText="Steps"
            expanded={stepsExpanded}
            onChange={({ detail }) => setStepsExpanded(detail.expanded)}
          >
            <ColumnLayout columns={1} variant="text-grid">
              {batch?.recipe?.steps.map((step, i) => {
                let thisStepStart = step.start;
                let thisStepEnd = step.end;

                // Calculate start and end dates if missing
                if (!thisStepStart && batch?.startDate) {
                  thisStepStart = addDaysBySteps(
                    toDate(batch.startDate),
                    (batch?.recipe?.steps ?? []).slice(0, i)
                  );
                } else if (thisStepStart) thisStepStart = toDate(thisStepStart);

                if (!thisStepEnd && batch?.startDate) {
                  thisStepEnd = addDaysBySteps(
                    toDate(batch.startDate),
                    (batch?.recipe?.steps ?? []).slice(0, i + 1)
                  );
                } else if (thisStepEnd) thisStepEnd = toDate(thisStepEnd);

                const duration =
                  Math.round(
                    (thisStepEnd?.getTime() ?? 0) -
                      (thisStepStart?.getTime() ?? 0)
                  ) /
                  (1000 * 60 * 60 * 24);

                return (
                  <div key={`step-${i}`} style={{ display: "flex", gap: 8 }}>
                    <Icon
                      key={`icon-${i}`}
                      name={
                        (batch?.currentStepIndex ?? 0) === i
                          ? "caret-right-filled"
                          : (batch?.currentStepIndex ?? 0) >= i
                          ? "check"
                          : "status-pending"
                      }
                    />
                    <Box key={`box-index-${i}`} variant="awsui-key-label">
                      {i + 1}.
                    </Box>
                    <Box key={`box-name-${i}`} variant="awsui-key-label">
                      {step.name}
                    </Box>
                    <Box key={`box-duration-${i}`}>
                      {duration.toFixed(0)} days
                    </Box>
                    <Box
                      key={`box-start-${i}`}
                      variant={step?.start ? "awsui-key-label" : undefined}
                    >
                      {thisStepStart?.toLocaleDateString()}
                    </Box>
                    {" - "}
                    <Box
                      key={`box-end-${i}`}
                      variant={step?.end ? "awsui-key-label" : undefined}
                    >
                      {thisStepEnd?.toLocaleDateString()}
                    </Box>
                    <Button
                      key={`button-${i}`}
                      onClick={() => setEditStep(i)}
                      variant="inline-icon"
                      iconName="status-pending"
                    />
                  </div>
                );
              })}
            </ColumnLayout>
          </ExpandableSection>
        </div>
        <div style={{ marginTop: 10 }}>
          <ExpandableSection
            variant="container"
            headerText="Cost"
            expanded={costExpanded}
            onChange={({ detail }) => setCostExpanded(detail.expanded)}
          >
            {/* Cost overview */}
            <ColumnLayout columns={2} variant="text-grid">
              <SpaceBetween size="m" direction="horizontal">
                <Box variant="awsui-key-label">Total cost</Box>
                <Box>€ {batch ? costTotal(batch).toFixed(2) : "0.00"}</Box>
              </SpaceBetween>
              <SpaceBetween size="m" direction="horizontal">
                <Box variant="awsui-key-label">Cost per liter</Box>
                <Box>€ {batch ? costPerUnit(batch).toFixed(2) : "0.00"}</Box>
              </SpaceBetween>

              <SpaceBetween size="m" direction="horizontal">
                <Box variant="awsui-key-label">Cost per bottle (0.75)</Box>
                <Box>
                  € {batch ? costPerBottle(batch, "1").toFixed(2) : "0.00"}
                </Box>
              </SpaceBetween>
              <SpaceBetween size="m" direction="horizontal">
                <Box variant="awsui-key-label">Cost per bottle (0.33)</Box>
                <Box>
                  € {batch ? costPerBottle(batch, "2").toFixed(2) : "0.00"}
                </Box>
              </SpaceBetween>
            </ColumnLayout>
          </ExpandableSection>
        </div>
        {batch?.hydroPi && <HydroPi config={batch.hydroPi} />}
        {gravitySamples && gravitySamples.length > 0 && (
          <div style={{ marginTop: 10 }}>
            <ExpandableSection
              variant="container"
              headerText="Gravity"
              expanded={gravityExpanded}
              onChange={({ detail }) => setGravityExpanded(detail.expanded)}
            >
              <ColumnLayout columns={1} variant="text-grid">
                {gravitySamples && gravitySamples.length > 0 && (
                  <LineChart
                    xScaleType="time"
                    series={[
                      {
                        title: "OG",
                        type: "line",
                        data: gravitySamples.map((sample) => ({
                          x: toDate(sample.date),
                          y: parseFloat(sample.og),
                        })),
                      },
                      {
                        title: "SG",
                        type: "line",
                        data: gravitySamples.map((sample) => ({
                          x: toDate(sample.date),
                          y: parseFloat(sample.sg),
                        })),
                      },
                      {
                        title: "FG",
                        type: "line",
                        data: gravitySamples.map((sample) => ({
                          x: toDate(sample.date),
                          y: parseFloat(sample.fg),
                        })),
                      },
                    ]}
                    yDomain={[0.98, 1.12]}
                    xDomain={
                      [
                        toDate(gravitySamples[0].date),
                        toDate(gravitySamples[gravitySamples.length - 1].date),
                      ] as [Date, Date]
                    }
                  />
                )}
                <div style={{ marginTop: 10 }}>
                  {gravitySamples.map((sample, i) => {
                    return (
                      <SpaceBetween
                        key={toDate(sample.date).toISOString() + i}
                        size="m"
                        direction="horizontal"
                      >
                        <Box variant="awsui-key-label">
                          {toDate(sample.date).toLocaleDateString()}
                        </Box>
                        <Box>{sample.og}</Box>
                        <Box variant="awsui-key-label">{sample.sg}</Box>
                        <Box>{sample.fg}</Box>
                        <Box>{sample.temperature}</Box>
                        <Box>{sample.comment}</Box>
                      </SpaceBetween>
                    );
                  })}
                </div>
              </ColumnLayout>
            </ExpandableSection>
          </div>
        )}

        {batch?.tasteSamples && (
          <div style={{ marginTop: 10 }}>
            <ExpandableSection
              variant="container"
              headerText="Taste"
              expanded={tasteExpanded}
              onChange={({ detail }) => setTasteExpanded(detail.expanded)}
            >
              <TasteVisualization batch={batch} />
            </ExpandableSection>
          </div>
        )}
      </ContentLayout>

      <TasteSampleModal
        visible={showTasteModal}
        onClose={() => setShowTasteModal(false)}
        onSave={handleAddTasteSample}
      />

      <Modal
        visible={editStep > -1}
        onDismiss={() => setEditStep(-1)}
        header={batch?.recipe?.steps[editStep]?.name ?? ""}
        footer={
          <div style={{ float: "right" }}>
            <SpaceBetween size="m" direction="horizontal">
              <Button onClick={() => setEditStep(-1)}>Cancel</Button>
              <Button
                variant="primary"
                onClick={() => {
                  setEditStep(-1);
                  saveBatch();
                }}
              >
                Save
              </Button>
            </SpaceBetween>
          </div>
        }
      >
        <SpaceBetween size="m" direction="horizontal">
          <FormField label="Start date">
            <DatePicker
              value={
                !!batch?.recipe?.steps[editStep]?.start
                  ? toDate(batch?.recipe?.steps[editStep]?.start ?? new Date())
                      ?.toISOString()
                      .split("T")[0] ?? ""
                  : ""
              }
              onChange={({ detail }) =>
                setBatch((prevBatch) => {
                  if (!prevBatch) return { ...initalBatch };
                  const newRecipe = prevBatch.recipe;
                  if (!newRecipe) return { ...initalBatch };
                  const newStep = newRecipe.steps[editStep];
                  if (!newStep) return { ...initalBatch };
                  newStep.start = new Date(detail.value);
                  return { ...prevBatch, recipe: newRecipe };
                })
              }
            />
          </FormField>
          <FormField label="End date">
            <DatePicker
              value={
                !!batch?.recipe?.steps[editStep]?.end
                  ? toDate(batch?.recipe?.steps[editStep]?.end ?? new Date())
                      ?.toISOString()
                      .split("T")[0] ?? ""
                  : ""
              }
              onChange={({ detail }) =>
                setBatch((prevBatch) => {
                  if (!prevBatch) return { ...initalBatch };
                  const newRecipe = prevBatch.recipe;
                  if (!newRecipe) return { ...initalBatch };
                  const newStep = newRecipe.steps[editStep];
                  if (!newStep) return { ...initalBatch };
                  newStep.end = new Date(detail.value);
                  return { ...prevBatch, recipe: newRecipe };
                })
              }
            />
          </FormField>
        </SpaceBetween>
      </Modal>
      <Modal
        visible={!!id && id !== "new" && modal === "gravity"}
        onDismiss={() => navigate("/batches/" + id)}
        header="Gravity sample"
      >
        {batch?.recipe ? (
          <GravitySample
            recipe={batch?.recipe}
            step={batch?.currentStepIndex ?? 0}
          />
        ) : (
          "No recipe found"
        )}
      </Modal>
      <Modal
        visible={showHydroPiModal}
        onDismiss={() => setShowHydroPiModal(false)}
        header="HydroPi Config"
      >
        <HydroPiConfigModal
          batchId={id}
          onClose={() => setShowHydroPiModal(false)}
        />
      </Modal>
      <UpdateQuantityModal
        visible={finishModal}
        onClose={() => setFinishModal(false)}
      />
    </>
  );
};

export default BatchPage;
