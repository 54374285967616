import {
  Button,
  DatePicker,
  Form,
  FormField,
  Select,
  SelectProps,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FC, useEffect, useState } from "react";
import { HydroPiColor, HydroPiColors, HydroPiConfig } from "../../models/Batch";
import { updateBatch } from "../../api/batches";
import { Timestamp } from "@firebase/firestore";
import { useAuthContext } from "../../contexts/AuthContext";

interface IHydroPiConfig {
  batchId?: string;
  onClose: () => void;
}

const HydroPiConfigModal: FC<IHydroPiConfig> = ({ batchId, onClose }) => {
  const { tenant } = useAuthContext();
  const [color, setColor] = useState<SelectProps.Option>();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const options: SelectProps.Option[] = HydroPiColors.map((value) => ({
    label: value,
    value,
  }));

  const handleSubmit = async () => {
    if (!batchId || !color) return;
    const hydroPi: HydroPiConfig = {
      color: color.value as HydroPiColor,
      startDate: Timestamp.fromDate(new Date(startDate)),
    };

    if (endDate) hydroPi.endDate = Timestamp.fromDate(new Date(endDate));

    await updateBatch(batchId, { hydroPi }, tenant);

    onClose();
  };

  return (
    <div>
      <Form>
        <SpaceBetween size="m">
          <FormField label="Tilt color">
            <Select
              selectedOption={color ?? { label: "Choose Tilt Color" }}
              options={options}
              onChange={(event) => setColor(event.detail.selectedOption)}
              placeholder="Select a color"
            />
          </FormField>
          <FormField
            label="Start date"
            description="What samples to add to this batch."
          >
            <SpaceBetween size="m" direction="horizontal">
              <DatePicker
                value={startDate}
                onChange={({ detail }) => {
                  setStartDate(detail.value);
                }}
              />
              <Button
                variant="normal"
                onClick={() => setStartDate(new Date().toISOString())}
              >
                Now
              </Button>
            </SpaceBetween>
          </FormField>
          <FormField
            label="End date"
            description="Leave empty if it's an active batch."
          >
            <DatePicker
              value={endDate}
              onChange={({ detail }) => {
                setEndDate(detail.value);
              }}
            />
          </FormField>
        </SpaceBetween>
      </Form>
      <div style={{ float: "right", paddingTop: 20 }}>
        <SpaceBetween size="m" direction="horizontal">
          <Button onClick={() => onClose()}>Close</Button>
          <Button variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </SpaceBetween>
      </div>
    </div>
  );
};

export default HydroPiConfigModal;
