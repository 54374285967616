import {
  Checkbox,
  ExpandableSection,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FC, useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { Filter, subscribeToFilteredChanges } from "../../api/generic";
import { prefixCollection } from "../../utils/prefix";
import { useAuthContext } from "../../contexts/AuthContext";
import { HydroPiConfig } from "../../models/Batch";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { toDate } from "../../utils/timestamp";

interface IHydroPiProps {
  config: HydroPiConfig;
}

export type HydroPiSample = {
  temp_stddev: number;
  color: string;
  uuid: string;
  gravity_stddev: number;
  avg_temp_c: number;
  timestamp: {
    seconds: number;
    nanoseconds: number;
  };
  avg_gravity: number;
  id: string;
};

type ChartData = {
  timestamp: string; // A formatted date or time string (e.g., "2024-11-23 12:16:00")
  avg_gravity: number; // Average gravity value
  avg_temp_c: number; // Average temperature in Celsius
  gravity_stddev?: number; // Optional standard deviation for gravity
  temp_stddev?: number; // Optional standard deviation for temperature
  color?: string; // Optional data category or label (e.g., "Black", "Yellow")
};

const HydroPi: FC<IHydroPiProps> = ({ config }) => {
  const { tenant } = useAuthContext();
  const [expanded, setExpanded] = useLocalStorage<boolean>(
    "hydropi-expanded",
    false
  );
  const [data, setData] = useState<HydroPiSample[]>();
  const [chartData, setChartData] = useState<ChartData[]>();
  const [sampleTypes, setSampleTypes] = useLocalStorage<string[]>(
    "sampleTypes",
    []
  );

  useEffect(() => {
    if (data) {
      try {
        let processedData = [...data]; // Clone the data to avoid mutation

        // Apply "spread" filter if selected
        if (sampleTypes?.includes("spread")) {
          const oneHourInMs = 60 * 60 * 1000; // 1 hour in milliseconds
          let lastTimestamp = 0;

          processedData = processedData.filter((item) => {
            const currentTimestamp = item.timestamp.seconds * 1000;

            if (currentTimestamp - lastTimestamp >= oneHourInMs) {
              lastTimestamp = currentTimestamp;
              return true;
            }
            return false;
          });
        }

        if (sampleTypes?.includes("downSize")) {
          const tenHoursInMs = 10 * 60 * 60 * 1000; // 10 hours in milliseconds

          processedData = processedData.reduce<HydroPiSample[]>((acc, item) => {
            const currentTimestamp = item.timestamp.seconds * 1000;

            // Check if the current sample is at least 10 hours apart from the last included sample
            if (
              acc.length === 0 || // Always include the first sample
              currentTimestamp - acc[acc.length - 1].timestamp.seconds * 1000 >=
                tenHoursInMs
            ) {
              acc.push(item); // Add the sample to the result
            }

            return acc;
          }, []);
        }

        // Validate startDate
        if (!config.startDate?.seconds) {
          console.error("Invalid startDate in config");
          return;
        }

        const startTime = config.startDate.seconds * 1000; // Convert startDate to milliseconds
        const transformedData = processedData.map((item) => ({
          timestamp: new Date(item.timestamp.seconds * 1000).toISOString(), // Convert to ISO string
          relativeTime:
            (item.timestamp.seconds * 1000 - startTime) / (1000 * 60 * 60), // Hours since start
          avg_gravity: item.avg_gravity,
          avg_temp_c: item.avg_temp_c,
          gravity_stddev: item.gravity_stddev ?? 0,
          temp_stddev: item.temp_stddev ?? 0,
          color: item.color,
        }));

        // Apply "oneWay" filter if selected
        if (sampleTypes?.includes("oneWay")) {
          transformedData.forEach((item, index) => {
            if (
              index > 0 &&
              item.avg_gravity > transformedData[index - 1].avg_gravity
            ) {
              item.avg_gravity = transformedData[index - 1].avg_gravity;
            }
          });
        }

        // Update the chart data
        setChartData(transformedData);
      } catch (error) {
        console.error("Error processing data:", error);
      }
    }
  }, [data, sampleTypes, config.startDate]);

  useEffect(() => {
    const filters: Filter<HydroPiSample>[] = [
      {
        field: "color",
        operator: "==",
        value: config?.color,
      },
      {
        field: "timestamp",
        operator: ">=",
        value: config.startDate,
      },
    ];

    if (config.endDate)
      filters.push({
        field: "timestamp",
        operator: "<",
        value: config.endDate,
      });

    const unsubscribe = subscribeToFilteredChanges<HydroPiSample>(
      prefixCollection("hydroPi", tenant, "-"),
      filters,
      setData,
      (error) => {
        console.error("Error:", error.message);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [config, tenant]);

  const handleToggle = (key: string, value: boolean) => {
    // Ensure `prev` is always an array
    const currentSampleTypes = sampleTypes ?? [];

    if (value) {
      // Add key if not already present
      if (!currentSampleTypes.includes(key)) {
        setSampleTypes([...currentSampleTypes, key]);
      }
    } else {
      // Remove key if it exists
      setSampleTypes(currentSampleTypes.filter((i) => i !== key));
    }
  };

  return (
    <div style={{ marginTop: 10 }}>
      <ExpandableSection
        variant="container"
        headerText="HydroPi"
        expanded={expanded}
        onChange={({ detail }) => setExpanded(detail.expanded)}
      >
        <div style={{ width: "100%", height: "400px" }}>
          <SpaceBetween size="m" direction={"horizontal"}>
            <Checkbox
              checked={sampleTypes?.includes("downSize") ?? false}
              onChange={({ detail }) =>
                handleToggle("downSize", detail.checked)
              }
              description={"Every 10 hours, not every hour"}
            >
              Downsize
            </Checkbox>
            <Checkbox
              checked={sampleTypes?.includes("oneWay") ?? false}
              onChange={({ detail }) => handleToggle("oneWay", detail.checked)}
              description={"Gravity can not go up"}
            >
              One way
            </Checkbox>
            <Checkbox
              checked={sampleTypes?.includes("spread") ?? false}
              onChange={({ detail }) => handleToggle("spread", detail.checked)}
              description={"Hide extra readings"}
            >
              Spread
            </Checkbox>
          </SpaceBetween>

          <ResponsiveContainer>
            <AreaChart
              data={chartData} // Use the transformed chart data
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="relativeTime" // Use numeric time for proportional spacing
                type="number" // Numeric axis for proportional spacing
                domain={["dataMin", "dataMax"]} // Fit the domain to the data range
                tickFormatter={(value) => {
                  const days = Math.floor(value / 24);
                  const hours = Math.floor(value % 24);
                  return `${days}d${hours.toString().padStart(2, "0")}h`; // Format as "XdHHh"
                }}
                label={{
                  value: "Time (days and hours)",
                  position: "insideBottom",
                  offset: -5,
                }}
              />

              <Tooltip
                labelFormatter={(value) => {
                  const days = Math.floor(value / 24);
                  const hours = Math.floor(value % 24);
                  return `${days} days ${hours} hours`; // Matches the X-axis format
                }}
              />
              <Legend />
              <YAxis
                yAxisId="left"
                domain={[0.99, "auto"]} // Start at 0.99, auto-scale the top
                label={{
                  value: "SG ",
                  position: "insideLeft",
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                domain={[
                  (dataMin: number) => Math.floor(dataMin), // Minimum is 3 below the data's minimum
                  (dataMax: number) => Math.ceil(dataMax), // Maximum is 3 above the data's maximum
                ]}
                label={{
                  value: "°C",
                  position: "insideRight",
                }}
              />

              {/* Area for Gravity */}
              <Area
                yAxisId="left"
                type="monotone"
                dataKey="avg_gravity"
                fill="url(#colorUv)" // Semi-transparent fill for gravity
                stroke="rgba(136, 132, 216, 1)" // Line for gravity
                name="Avg Gravity"
                dot={false} // Hide dots by default
                activeDot={{ r: 8 }} // Show larger points on hover
              />

              {/* Area for Temperature */}
              <Area
                yAxisId="right"
                type="monotone" // Smooth line, change to "basis" for even more rounding
                dataKey="avg_temp_c"
                fill="url(#colorPv)" // Semi-transparent fill for temperature
                stroke="rgba(255, 99, 132, 1)" // Line color for temperature
                name="Avg Temp (°C)"
                dot={false} // Hide dots by default
                activeDot={{ r: 8 }} // Show dots on hover
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div style={{ marginBottom: 50 }}></div>
      </ExpandableSection>
    </div>
  );
};

export default HydroPi;
