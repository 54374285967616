/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  ContentLayout,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";

import { createItem, updateItem as updateInventoryItem } from "../api/generic";
import { fetchInventoryItem } from "../api/ingredients";
import InventoryItem from "../models/InventoryItem";
import { prefixCollection } from "../utils/prefix";
import { useAuthContext } from "../contexts/AuthContext";

const InventoryItemPage: FC = () => {
  let { id } = useParams();

  const { tenant } = useAuthContext();
  const navigate = useNavigate();

  const [item, setItem] = useState<InventoryItem | null>(null);
  const [quantity, setQuantity] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [validate, setValidate] = useState<boolean>(false);

  useEffect(() => {
    const fetchItem = async () => {
      if (!id || id === "new") return;
      const i = await fetchInventoryItem(id, tenant);
      setItem(i);
      setQuantity(i?.quantity.toString() ?? "");
      setPrice(i?.price?.toString() ?? "");
      setValidate(true);
      if (i === null) {
        navigate("/Inventory");
      }
    };

    fetchItem();
  }, [id]);

  const addItem = async (item: Omit<InventoryItem, "id">) => {
    await createItem<InventoryItem>(prefixCollection("inventory", tenant), item);
  };

  const updateItem = async (item: InventoryItem) => {
    await updateInventoryItem<InventoryItem>(prefixCollection("inventory", tenant), item.id, item);
  };

  const submitForm = async () => {
    setValidate(true);

    if (item === null) return;

    if (item.name === "") return;
    if (item.unit === "") return;

    if (id === "new") {
      await addItem({
        name: item.name,
        unit: item.unit,
        category: item.category,
        quantity: parseFloat(quantity),
        price: parseFloat(price),
      });
    } else {
      updateItem({
        ...item,
        quantity: parseFloat(quantity),
        price: parseFloat(price),
      });
    }

    navigate("/Inventory");
  };

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description={id === "new" ? "Create new ingredient" : id}
        >
          {item?.name ?? id === "new"
            ? "Creating new ingredient"
            : "Loading..."}
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={() => window.history.back()}
                formAction="none"
                variant="link"
              >
                Cancel
              </Button>
              <Button onClick={submitForm} variant="primary">
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Ingredient name">
              <Input
                invalid={validate && !item?.name}
                value={item?.name ?? ""}
                onChange={(event) => {
                  const newValue = event.detail.value;

                  setItem((prevItem) => {
                    if (prevItem === null) {
                      return {
                        name: newValue,
                        quantity: 0,
                        price: 0,
                        id: "",
                        unit: "",
                      };
                    } else {
                      return { ...prevItem, name: newValue };
                    }
                  });
                }}
              />
            </FormField>
            <FormField label="Quantity">
              <Input
                value={quantity}
                onChange={({ detail }) => setQuantity(detail.value)}
              />
            </FormField>
            <FormField label="Unit">
              <Input
                invalid={validate && !item?.unit}
                value={item?.unit ?? ""}
                onChange={(event) => {
                  const newValue = event.detail.value;

                  setItem((prevItem) => {
                    if (prevItem === null) {
                      return {
                        name: "",
                        quantity: 0,
                        price: 0,
                        id: "",
                        unit: newValue,
                      };
                    } else {
                      return { ...prevItem, unit: newValue };
                    }
                  });
                }}
              />
            </FormField>
            <FormField label="Price">
              <Input
                value={price}
                onChange={({ detail }) => setPrice(detail.value)}
              />
            </FormField>
            <FormField label="Category">
              <Input
                value={item?.category ?? ""}
                onChange={(event) => {
                  const newValue = event.detail.value;

                  setItem((prevItem) => {
                    if (prevItem === null) {
                      return {
                        name: "",
                        quantity: 0,
                        price: 0,
                        id: "",
                        unit: "",
                        category: newValue,
                      };
                    } else {
                      return { ...prevItem, category: newValue };
                    }
                  });
                }}
              />
            </FormField>
          </SpaceBetween>
        </Form>
      </div>
    </ContentLayout>
  );
};

export default InventoryItemPage;
