import { Timestamp } from "@firebase/firestore";

import { FermentationStatus } from "../utils/hydroPi";
import { Recipe } from "./Recipe";
import { TasteSample } from "./Sampling";

export type BatchStatus = "planned" | "in-progress" | "completed" | "archived";
export type StepStaus = "not-started" | "in-progress" | "completed";
export const HydroPiColors = [
  "Black",
  "Yellow",
  "Red",
  "Blue",
  "Green",
] as const;
export type HydroPiColor = (typeof HydroPiColors)[number];

export type Batch = {
  id: string; // Unique identifier for the batch
  name: string; // Name of the batch
  batchId?: string; // YEAR_INCREMENT
  recipeId: string; // ID of the recipe used
  recipe?: Recipe; // Recipe used
  startDate: Timestamp | Date; // Date when the batch started
  endDate?: Timestamp | Date; // Date when the batch ended (optional)
  status: BatchStatus; // Status of the
  quantity: number; // Quantity produced in this batch
  volume: number; // Starting volume produced in this batch
  yield: number; // Yield of the batch
  expectedYield: number; // Expected yield of the batch
  currentStepIndex: number; // Index of the current step in the recipe's steps
  stepStatus: StepStaus; // Status of the current
  notes?: string; // Optional notes about the batch
  score?: number;
  hydroPi?: HydroPiConfig;
  tasteSamples?: TasteSample[];
};

export type HydroPiConfig = {
  color: HydroPiColor;
  startDate: Timestamp;
  endDate?: Timestamp;
};

export interface BatchViewModel extends Batch {
  abvNow: string; // Current ABV based on the latest sample
  abvFinal: string; // Final ABV calculation
  currentStepName: string; // Current step name from the recipe
  progress: number; // Progress percentage calculated
  costL: string; // Cost per liter calculated to two decimal places
  nextActionDate: string;
  fStatus?: FermentationStatus;
}
