/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

import { BoardItem } from "@cloudscape-design/board-components";
import Board, { BoardProps } from "@cloudscape-design/board-components/board";
import {
  ContentLayout,
  Header,
  KeyValuePairs,
  Link,
} from "@cloudscape-design/components";

import { subscribeToBatchesByStatus } from "../api/batches";
import { fetchItemsWithFilter } from "../api/generic";
import Loader from "../components/Loader";
import { useAuthContext } from "../contexts/AuthContext";
import { Batch } from "../models/Batch";
import Tenant from "../models/Tenant";
import { exportLayout, StoredWidgetPlacement } from "../utils/layout";
import CalendarWidget from "../widgets/CalendarWidget";
import NextStepsWidget from "../widgets/NextStepsWidget";
import { boardI18nStrings } from "./i18n-strings";

const dataInit = { id: "" };

type WidgetId = "batchOverview" | "nextSteps" | "calendar" | "default";

const defaultLayouts: ReadonlyArray<BoardProps.Item<StoredWidgetPlacement>> = [
  {
    id: "batchOverview",
    columnOffset: { 4: 0 },
    columnSpan: 2,
    rowSpan: 3,
    data: dataInit,
  },
  {
    id: "nextSteps",
    columnOffset: { 4: 2 },
    columnSpan: 2,
    rowSpan: 3,
    data: dataInit,
  },
  {
    id: "calendar",
    columnOffset: { 4: 0 },
    columnSpan: 4,
    rowSpan: 7,
    data: dataInit,
  },
];

const Home: FC = () => {
  const { tenant, currentUser } = useAuthContext();
  const [tenantItem, setTenantItem] = useState<Tenant>();
  const [batches, setBatches] = useState<Batch[]>();
  const [layout, setLayout] = useLocalStorage<
    ReadonlyArray<StoredWidgetPlacement>
  >("home-layout", defaultLayouts);

  function handleLayoutChange(layout: ReadonlyArray<StoredWidgetPlacement>) {
    setLayout(layout);
  }

  const fetchTenant = async () => {
    const filteredItems = await fetchItemsWithFilter<Tenant>("tenant", [
      {
        field: "name",
        operator: "==",
        value: tenant,
      },
    ]);

    setTenantItem(filteredItems[0]);
  };

  useEffect(() => {
    fetchTenant();
  }, [tenant]);

  useEffect(() => {
    const unsubscribe = subscribeToBatchesByStatus(
      ["planned", "in-progress"],
      tenant,
      (batches) => setBatches(batches)
    );
    return unsubscribe;
  }, []);

  const totalActiveBatches = batches?.filter(
    (batch) => batch.status === "in-progress"
  ).length;
  const plannedBatches = batches?.filter(
    (batch) => batch.status === "planned"
  ).length;

  const dataForLayout: Record<
    WidgetId,
    {
      title: string;
      description: string;
      header: () => JSX.Element;
      content: () => JSX.Element;
    }
  > = {
    default: {
      title: "Widget not found",
      description:
        "The widget was not found in the widget collection. Seems one of our developers has had a few too many glasses of mead. We will fix it soon...",
      header: () => <Header>Obs...</Header>,
      content: () => <></>,
    },
    nextSteps: {
      title: "Next Steps",
      description: "Here are the next 5 steps for all batches",
      header: () => <Header>Upcoming steps</Header>,
      content: () => (
        <Loader isLoading={!batches}>
          <NextStepsWidget batches={batches ?? []} />
        </Loader>
      ),
    },
    calendar: {
      title: "Calendar",
      description: "Calendar",
      header: () => <Header>Calendar</Header>,
      content: () => (
        <Loader isLoading={!batches}>
          <CalendarWidget batches={batches ?? []} />
        </Loader>
      ),
    },
    batchOverview: {
      title: "Batch Overview",
      description: "Overview of active and planned batches",
      header: () => <Header>Batch Overview</Header>,
      content: () => (
        <KeyValuePairs
          columns={4}
          items={[
            {
              label: "Active",
              value: (
                <Link variant="awsui-value-large" href="/batches">
                  {totalActiveBatches}
                </Link>
              ),
            },
            {
              label: "Planned",
              value: (
                <Header>
                  <Link variant="awsui-value-large" href="/planning">
                    {plannedBatches}
                  </Link>
                </Header>
              ),
            },
          ]}
        />
      ),
    },
  };

  const mappedLayout = layout
    ? layout.map((item) => ({
        ...item,
        data: dataForLayout[item.id as WidgetId] ?? dataForLayout.default,
      }))
    : [];

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header variant="h1" description={`${tenantItem?.displayName ?? ""}`}>
          Producery
        </Header>
      }
    >
      {" "}
      {!currentUser ? null : (
        <div style={{ marginTop: 10 }}>
          <Board
            items={mappedLayout}
            i18nStrings={boardI18nStrings}
            empty="No metrics available"
            onItemsChange={({ detail: { items } }) => {
              handleLayoutChange(exportLayout(items));
            }}
            renderItem={(item, actions) => (
              <BoardItem
                i18nStrings={{
                  dragHandleAriaLabel: "Drag handle",
                  dragHandleAriaDescription:
                    "Use Space or Enter to activate drag, arrow keys to move, Space or Enter to submit, or Escape to discard.",
                  resizeHandleAriaLabel: "Resize handle",
                  resizeHandleAriaDescription:
                    "Use Space or Enter to activate resize, arrow keys to move, Space or Enter to submit, or Escape to discard.",
                }}
                header={
                  item.data.header ? (
                    <item.data.header />
                  ) : (
                    <Header>Board item title</Header>
                  )
                }
              >
                {item.data.content ? (
                  <item.data.content />
                ) : (
                  "Board item content"
                )}
              </BoardItem>
            )}
          />
        </div>
      )}
    </ContentLayout>
  );
};

export default Home;
