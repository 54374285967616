import "@cloudscape-design/global-styles/index.css";

import { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useLocalStorage, useLocation } from "react-use";

import { AppLayout, SplitPanel } from "@cloudscape-design/components";

import AbvCalculator from "./components/AbvCalculator";
import Hint from "./components/Hint";
import HoneyCalculator from "./components/HoneyCalculator";
import { AuthContextProvider } from "./contexts/AuthContext";
import GetAuthContext from "./contexts/GetAuthContext";
import Approve from "./layout/Approve";
import Loginout from "./layout/Loginout";
import { PrintRouting } from "./layout/PrintRouting";
import { Routing } from "./layout/Routing";
import Sidebar from "./layout/Sidebar";
import { UserData } from "./models/UserData";
import version from "./v.json";

function App() {
  const location = useLocation();
  const [nav, setNav] = useLocalStorage("nav", true);

  const [tool, setTool] = useLocalStorage("tool", false);
  const [userObject, setUserObject] = useState<UserData | null>(null);

  return (
    <AuthContextProvider>
      <GetAuthContext setUserObject={setUserObject} />
      <Router>
        {location.pathname?.endsWith("/print") ? (
          <PrintRouting />
        ) : (
          <AppLayout
            navigationOpen={nav}
            onNavigationChange={({ detail }) => setNav(detail.open)}
            toolsOpen={tool}
            onToolsChange={({ detail }) => setTool(detail.open)}
            splitPanel={
              <SplitPanel
                header={`${
                  userObject?.name ?? "No user"
                } - The Horrible Meadery © 2024 ${
                  version.MajorMinorPatch +
                  "." +
                  version.CommitsSinceVersionSource
                }`}
                hidePreferencesButton
              >
                <p>Latest changes:</p>
                <ul>
                  <li>
                    <strong>Improvments for HydroPi integration.</strong>
                    <br />
                    Less redundant data and better handling of the chart
                  </li>
                  <li>
                    <strong>Suggestions filter added.</strong>
                    <br />A filter for each state. Not Closed is the default
                    filter. Local storage keeps track between sessions.-{" "}
                    <small>(Toggle suggestions between open/closed/all)</small>
                  </li>
                  <li>
                    <strong>ELO sorted sidebare removed.</strong>
                    <br />
                    Sorted by title, not global usage. -{" "}
                    <small>(Alphabetise the navigation links)</small>
                  </li>
                  <li>
                    <strong>Batch archiving functionality added.</strong>
                    <br />
                    Allows users to select and archive multiple batches.
                    Includes a toggleable archiving mode and user feedback for
                    operations. -{" "}
                    <small>(Multi select option for archiving batches)</small>
                  </li>
                </ul>
              </SplitPanel>
            }
            navigation={
              <>
                <Sidebar />
                <Approve />
                <Loginout />
              </>
            }
            content={<Routing />}
            tools={
              <div style={{ marginTop: 40, padding: 8 }}>
                <Hint />
                <AbvCalculator />
                <HoneyCalculator />
              </div>
            }
          />
        )}
      </Router>
    </AuthContextProvider>
  );
}

export default App;
