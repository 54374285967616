import { FC, useEffect } from "react";

import { UserData } from "../models/UserData";
import { useAuthContext } from "./AuthContext";

interface IGetAuthContext {
  setUserObject: (user: UserData | null) => void;
}

const GetAuthContext: FC<IGetAuthContext> = ({ setUserObject }) => {
  const { userObject } = useAuthContext();

  useEffect(() => {
    setUserObject(userObject);
  }, [userObject]);
  return null;
};

export default GetAuthContext;
