import { useEffect, useState } from "react";
import { v4 } from "uuid";

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Header,
  Input,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";

import { fetchItemsWithFilter, updateItem } from "../api/generic";
import { useAuthContext } from "../contexts/AuthContext";
import Tenant from "../models/Tenant";

const HydroPi = () => {
  const { tenant } = useAuthContext();
  const [apiKey, setApiKey] = useState<string>();
  const [tenantObj, setTenantObj] = useState<Tenant>();
  const [loading, setLoading] = useState(true);

  const handleSaveInTenant = async () => {
    if (tenantObj) {
      setLoading(true);
      tenantObj.hydroPi = apiKey;
      await updateItem<Tenant>("tenant", tenantObj.id, tenantObj);
      setLoading(false);
    }
  };

  const fetchTenant = async () => {
    const tenantObject = (
      await fetchItemsWithFilter<Tenant>("tenant", [
        {
          field: "name",
          operator: "==",
          value: tenant,
        },
      ])
    )[0];

    setTenantObj(tenantObject);
    setLoading(false);
  };

  useEffect(() => {
    fetchTenant();
  }, []);

  return (
    <Box padding={{ vertical: "l", horizontal: "m" }}>
      <SpaceBetween size="l">
        <Header variant="h1">Generate API Key</Header>
        <Box>
          <p>
            Click the button below to generate a new API key for your
            application.
          </p>
          <Button onClick={() => setApiKey(v4())} variant="primary">
            {"Generate API Key"}
          </Button>
        </Box>
        {apiKey && (
          <Box>
            <Header variant="h3">Generated API Key - HydroPi</Header>
            <SpaceBetween size="m">
              <Input value={apiKey} readOnly ariaLabel="Generated API Key" />
              <Button onClick={handleSaveInTenant} variant="normal">
                {loading ? <Spinner /> : "Use as HydroPi API Key"}
              </Button>
            </SpaceBetween>
          </Box>
        )}
      </SpaceBetween>
    </Box>
  );
};

export default HydroPi;
