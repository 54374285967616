import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Button,
  ContentLayout,
  Header,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";

import { deleteItem } from "../api/generic";
import DisplayImage from "../components/DisplayImage";
import ErrorBar from "../components/ErrorBar";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import { Recipe } from "../models/Recipe";
import { hexToRgbObj } from "../utils/color";
import { prefixCollection } from "../utils/prefix";
import { sortName } from "../utils/sort";
import AiRecipe from "../preview/openai/AiRecipe";

const Recipes = () => {
  const { tenant } = useAuthContext();
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [viewModel, setViewModel] = useState<any[]>([]);
  const [error, setError] = useState<Error>();

  const isColorLight = (color: any) => {
    const rgb = hexToRgbObj(color);
    return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 128;
  };

  const transformData = (recipesData: Recipe[]) => {
    return recipesData.map((item) => ({
      ...item,
      isColorLight: item.color ? isColorLight(item.color) : true,
      displayColor: item.color ?? "whitesmoke",
      textColor: item.color && isColorLight(item.color) ? "black" : "white",
      ingredientDisplay: item.ingredients.map(
        (ingredient) => ingredient.item.name.split("(")[0].split("-")[0]
      ),
    }));
  };

  useSubscribe<Recipe>(
    prefixCollection("recipes", tenant),
    setRecipes,
    setError,
    sortName
  );

  useEffect(() => {
    setViewModel(transformData(recipes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipes]);

  const deleteRecipe = async (item: Recipe) => {
    const confirm = window.confirm(
      `Are you sure you want to delete ${item.name}?`
    );
    if (!confirm) return;

    await deleteItem(prefixCollection("recipes", tenant), item.id);
  };

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description={"Hint: Create ingredients before you create recipes"}
          actions={
            <SpaceBetween size="m" direction="horizontal">
              <Button
                iconName="add-plus"
                variant="primary"
                onClick={() => navigate("/recipes/new")}
              >
                Create recipe
              </Button>
              {/* <AiRecipe /> */}
            </SpaceBetween>
          }
        >
          Recipes
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <ErrorBar error={error} setError={setError} />
        <Table
          wrapLines
          variant="embedded"
          items={viewModel}
          columnDefinitions={[
            {
              header: "Image",
              cell: (item) => (
                <DisplayImage
                  fileName="image"
                  filePath={"recipes/" + item.id}
                  round
                  altText="Recipe image"
                  style={{ height: 50, width: 50 }}
                />
              ),
            },
            {
              header: "Name",
              cell: (item) => (
                <Link to={`/recipes/${item.id}`}>
                  <strong>{item.name}</strong>
                </Link>
              ),
              isRowHeader: true,
            },
            {
              header: "Style",
              cell: (item) => (
                <div
                  style={{
                    borderRadius: 4,
                    color: item.textColor,
                    backgroundColor: item.displayColor,
                    padding: 4,
                  }}
                >
                  {item.style}
                </div>
              ),
            },
            {
              header: "Description",
              cell: (item) => <>{item.description}</>,
            },
            {
              header: "Steps",
              cell: (item) => <>{item.steps.length}</>,
            },
            {
              header: "Actions",
              cell: (item) => (
                <>
                  <Button
                    iconName="remove"
                    variant="inline-link"
                    onClick={() => deleteRecipe(item)}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </ContentLayout>
  );
};

export default Recipes;
